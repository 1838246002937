import React, { memo } from "react";
import PropTypes from "prop-types";

import BackgroundImg from "./background-img";
import styles from "../../styles/hero-image.module.scss";

const HeroImage = ({ title, image, description, className }) => (
  <header className={`${styles.header} ${className}`}>
    <BackgroundImg image={image} alt={title} />
    <div className={styles.description}>
      <h1 className="title is-size-2-mobile is-size-1-tablet">{title}</h1>
      <h2 className="subtitle is-size-5-mobile is-size-4-tablet">
        {description}
      </h2>
    </div>
  </header>
);

HeroImage.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.shape({ fluid: PropTypes.object }).isRequired,
  description: PropTypes.string.isRequired,
  className: PropTypes.string
};

HeroImage.defaultProps = {
  className: ""
};

export default memo(HeroImage);
