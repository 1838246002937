import React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import Img from "gatsby-image";

import Layout from "../components/layout";
import HeroImage from "../components/hero-image";
import Carousel from "../components/carousel";

function IndexPage({ data }) {
  const activities = data.activities.edges.map(({ node }) => {
    const { title, description, image } = node.frontmatter;
    return (
      <HeroImage
        key={title}
        title={title}
        description={description}
        image={image.childImageSharp}
      />
    );
  });

  const clients = data.clients.edges.map(({ node }) => {
    const { title, image } = node.frontmatter;
    return <Img key={title} fluid={image.childImageSharp.fluid} alt={title} />;
  });

  return (
    <Layout title="Home">
      <Carousel autoPlayInterval={8000}>{activities}</Carousel>
      <section className="section">
        <div
          className="container content is-medium"
          dangerouslySetInnerHTML={{ __html: data.homeContent.html }}
        />
      </section>
      <section className="section">
        <div className="container">
          <h2 className="title is-2">I nostri clienti</h2>
          <Carousel responsive transitionTime={1000}>
            {clients}
          </Carousel>
        </div>
      </section>
    </Layout>
  );
}

export const query = graphql`
  query IndexQuery {
    activities: allMarkdownRemark(
      filter: { frontmatter: { key: { eq: "activity" } } }
      sort: { order: ASC, fields: frontmatter___order }
    ) {
      edges {
        node {
          frontmatter {
            title
            description
            image {
              childImageSharp {
                fluid(quality: 90, maxWidth: 2048) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    }
    clients: allMarkdownRemark(
      filter: { frontmatter: { key: { eq: "client" } } }
    ) {
      edges {
        node {
          frontmatter {
            title
            image {
              childImageSharp {
                fluid(quality: 70) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    }
    homeContent: markdownRemark(
      frontmatter: { key: { eq: "content" }, title: { eq: "Home" } }
    ) {
      html
    }
  }
`;

IndexPage.propTypes = {
  data: PropTypes.shape({
    activities: PropTypes.object,
    clients: PropTypes.object,
    homeContent: PropTypes.object
  }).isRequired
};

export default IndexPage;
