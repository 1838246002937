import React, { memo } from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";

// Hack to use gatsby image as a background image.
// The style prop MUST be used.
const BackgroundImg = ({ image, alt }) => (
  <Img
    style={{
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      zIndex: -1
    }}
    fluid={image.fluid}
    alt={alt}
  />
);

BackgroundImg.propTypes = {
  image: PropTypes.shape({ fluid: PropTypes.object }).isRequired,
  alt: PropTypes.string.isRequired
};

export default memo(BackgroundImg);
