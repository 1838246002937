import React, { memo } from "react";
import PropTypes from "prop-types";
import AliceCarousel from "react-alice-carousel";

import styles from "../styles/carousel.module.scss";
import "react-alice-carousel/lib/alice-carousel.css";

const Carousel = ({
  autoPlay,
  autoPlayInterval,
  transitionTime,
  responsive,
  breakpoints,
  className,
  children
}) => {
  const isMultiple =
    responsive && Object.keys(breakpoints).some(k => breakpoints[k].items > 1);

  return (
    <div className={`${styles.carousel} ${className}`}>
      <AliceCarousel
        autoPlay={autoPlay}
        autoPlayInterval={autoPlayInterval}
        buttonsDisabled
        dotsDisabled
        duration={transitionTime}
        mouseTrackingEnabled
        playButtonDisabled
        responsive={responsive ? breakpoints : {}}
        items={children.map(c => (
          <div
            onDragStart={e => e.preventDefault()}
            className={`${styles.item} ${isMultiple ? styles.isMultiple : ""}`}
          >
            {c}
          </div>
        ))}
      />
    </div>
  );
};

Carousel.propTypes = {
  autoPlay: PropTypes.bool,
  autoPlayInterval: PropTypes.number,
  transitionTime: PropTypes.number,
  responsive: PropTypes.bool,
  breakpoints: PropTypes.objectOf(PropTypes.shape({ items: PropTypes.number })),
  className: PropTypes.string,
  children: PropTypes.node.isRequired
};

Carousel.defaultProps = {
  autoPlay: true,
  autoPlayInterval: 3000,
  transitionTime: 250,
  responsive: false,
  breakpoints: {
    1024: {
      items: 4
    },
    768: {
      items: 3
    },
    425: {
      items: 2
    },
    0: {
      items: 1
    }
  },
  className: ""
};

export default memo(Carousel);
